html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    scroll-behavior: smooth;
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

#__next {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hamburger-not-visible .hamburger-react {
    cursor: default !important;
}

.StripeElement {
    font-size: 16px;
    width: 100%;
}

.StripeElement--focus {
    border-color: #0000d4;
}

.card-internal-description ::marker {
    color: #f9a000;
    content: "\2605";
    padding-right: 10px;
}

.wr-icon {
    transform-origin: 50% 50%;
    vertical-align: middle;
    transition: transform 0.35s ease-out;
    flex-shrink: 0;
}

.wr-icon-rotated {
    transform: rotate(180deg);
}

.wr-illustration {
    display: flex;
    flex-shrink: 0;
}

@keyframes homepageAnimationScrollDown {
    0% {
        transform: scaleY(0);
        transform-origin: 0 0;
    }

    40% {
        transform: scaleY(.99);
        transform-origin: 0 0;
    }

    40.01% {
        transform: scaleY(1);
        transform-origin: 0 100%;
    }

    80% {
        transform: scaleY(0);
        transform-origin: 0 100%;
    }

    100% {
        transform: scaleY(0);
        transform-origin: 0 100%;
    }
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisThin.otf) format('opentype'),
        url(/fonts/RebrandDisThin.ttf) format('truetype'), url(/fonts/RebrandDisThin.woff) format('woff'),
        url(/fonts/RebrandDisThin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisThinit.otf) format('opentype'),
        url(/fonts/RebrandDisThinit.ttf) format('truetype'), url(/fonts/RebrandDisThinit.woff) format('woff'),
        url(/fonts/RebrandDisThinit.woff2) format('woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisExLight.otf) format('opentype'),
        url(/fonts/RebrandDisExLight.ttf) format('truetype'), url(/fonts/RebrandDisExLight.woff) format('woff'),
        url(/fonts/RebrandDisExLight.woff2) format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisExLightit.otf) format('opentype'),
        url(/fonts/RebrandDisExLightit.ttf) format('truetype'), url(/fonts/RebrandDisExLightit.woff) format('woff'),
        url(/fonts/RebrandDisExLightit.woff2) format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisLight.otf) format('opentype'),
        url(/fonts/RebrandDisLight.ttf) format('truetype'), url(/fonts/RebrandDisLight.woff) format('woff'),
        url(/fonts/RebrandDisLight.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisLightit.otf) format('opentype'),
        url(/fonts/RebrandDisLightit.ttf) format('truetype'), url(/fonts/RebrandDisLightit.woff) format('woff'),
        url(/fonts/RebrandDisLightit.woff2) format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisRegular.otf) format('opentype'),
        url(/fonts/RebrandDisRegular.ttf) format('truetype'), url(/fonts/RebrandDisRegular.woff) format('woff'),
        url(/fonts/RebrandDisRegular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisRegularit.otf) format('opentype'),
        url(/fonts/RebrandDisRegularit.ttf) format('truetype'), url(/fonts/RebrandDisRegularit.woff) format('woff'),
        url(/fonts/RebrandDisRegularit.woff2) format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisMedium.otf) format('opentype'),
        url(/fonts/RebrandDisMedium.ttf) format('truetype'), url(/fonts/RebrandDisMedium.woff) format('woff'),
        url(/fonts/RebrandDisMedium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisMediumit.otf) format('opentype'),
        url(/fonts/RebrandDisMediumit.ttf) format('truetype'), url(/fonts/RebrandDisMediumit.woff) format('woff'),
        url(/fonts/RebrandDisMediumit.woff2) format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisSemiBold.otf) format('opentype'),
        url(/fonts/RebrandDisSemiBold.ttf) format('truetype'), url(/fonts/RebrandDisSemiBold.woff) format('woff'),
        url(/fonts/RebrandDisSemiBold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisSemiBoldit.otf) format('opentype'),
        url(/fonts/RebrandDisSemiBoldit.ttf) format('truetype'), url(/fonts/RebrandDisSemiBoldit.woff) format('woff'),
        url(/fonts/RebrandDisSemiBoldit.woff2) format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisBold.otf) format('opentype'),
        url(/fonts/RebrandDisBold.ttf) format('truetype'), url(/fonts/RebrandDisBold.woff) format('woff'),
        url(/fonts/RebrandDisBold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisBoldit.otf) format('opentype'),
        url(/fonts/RebrandDisBoldit.ttf) format('truetype'), url(/fonts/RebrandDisBoldit.woff) format('woff'),
        url(/fonts/RebrandDisBoldit.woff2) format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisExBold.otf) format('opentype'),
        url(/fonts/RebrandDisExBold.ttf) format('truetype'), url(/fonts/RebrandDisExBold.woff) format('woff'),
        url(/fonts/RebrandDisExBold.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisExBoldit.otf) format('opentype'),
        url(/fonts/RebrandDisExBoldit.ttf) format('truetype'), url(/fonts/RebrandDisExBoldit.woff) format('woff'),
        url(/fonts/RebrandDisExBoldit.woff2) format('woff2');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisHeavy.otf) format('opentype'),
        url(/fonts/RebrandDisHeavy.ttf) format('truetype'), url(/fonts/RebrandDisHeavy.woff) format('woff'),
        url(/fonts/RebrandDisHeavy.woff2) format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'RebrandDis';
    src: local('RebrandDis'), url(/fonts/RebrandDisHeavyit.otf) format('opentype'),
        url(/fonts/RebrandDisHeavyit.ttf) format('truetype'), url(/fonts/RebrandDisHeavyit.woff) format('woff'),
        url(/fonts/RebrandDisHeavyit.woff2) format('woff2');
    font-weight: 900;
    font-style: italic;
}